import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../../components/layout"
import Title from "../../components/title"
// import Hero from "../../blocks/Hero"
import { FixedBanner } from "../../blocks/Banner"
import TextWithImage from "../../blocks/TextWithImage"

const Marketplace = () => (
  <Layout>
    {/* <Hero
      data={{
        title: "Paybase for your online marketplace",
        copy:
          '"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur non lorem ultrices lobortis. Donec in mauris sed eros volutpat tempor eget nec purus. Duis sagittis tempor volutpat. Pellentesque ornare justo in felis efficitur, non semper nisi imperdiet. Morbi rutrum tellus nisl."',
        companyLogo: {
          src: "/who-is-it-for/marketplace/airbnb.png",
          alt: "John Smith, Product manager at Fast banana",
          copy: "John Smith, Product manager at Fast banana",
        },
        image: {
          src: "/who-is-it-for/marketplace/img2.png",
          alt: "placeholder image",
        },
        color1: "blue",
        color2: "lightblue",
        color3: "yellow",
      }}
    /> */}
    <Helmet>
      <title>Paybase - Marketplaces</title>
      <meta property="og:title" content="Paybase - Marketplaces" />
    </Helmet>
    <TextWithImage
      data={{
        title: "Paybase for online marketplaces",
        content:
          "Route payments between your buyers and sellers whilst automatically receiving your commission fee as the marketplace. Sellers can then withdraw to their bank accounts at a time that suits them, or have their balance withdrawn automatically at a time that suits you! No operational overhead. No reconciliation. Paybase handles everything whilst you get paid.",
        image: {
          src: "/who-is-it-for/marketplace/img1.svg",
          alt: "placeholder alt",
        },
        links: [
          { copy: "Get in touch", to: "https://trustshare.co" },
          {
            copy: "Developer Centre",
            to: "https://developers.paybase.io/recipes/marketplaces",
            color: "white",
            bg: "black",
            shadow: "white",
            hoverColor: "black",
          },
        ],
      }}
      options={{
        titleColor: "blue",
        copyColor: "grey",
        backgroundColor: "lightblue",
        padding: "3rem",
      }}
    />
    <Title copy="Using Paybase’s capabilities on your marketplace?" />
    <TextWithImage
      data={{
        title: "Split Payments_",
        content:
          "If you’re a fashion marketplace, you may wish to instantly reward social media influencers for driving sales to your site. With Paybase, payments can be split multiple times, meaning that if a buyer purchases through a social media link, the influencer can be credited instantly. This can be in the form of a flat fee or a percentage of your platform commission - it’s up to you.",
        animation: "/animations/homepage/split-payments.json",
        links: [{ copy: "Find out more", to: "/what-we-offer#split" }],
      }}
    />
    <TextWithImage
      data={{
        title: "Loyalty for Merchants_",
        content:
          "As a form of seller retention, you may wish to offer special rewards, offers and discounts that are limited to a given time period. If you’re a marketplace offering consumer gifts, you may want to decrease commission fees during the holiday period to incentivise sales or to offer no fees on the week of the seller’s birthday. The options are limitless!",
        links: [{ copy: "Find out more", to: "/what-we-offer#reward" }],
      }}
      options={{ isTextRight: true, titleColor: "rose" }}
    />
    <TextWithImage
      data={{
        title: "White Label Escrow_",
        content:
          "If your marketplace specialises in high-value items, you may wish to introduce an escrow service. Escrow allows payments to be held between buyers and sellers until the buyer confirms the item has been received in the expected condition. This can be taken further, offering the buyer 20% of the cost back if the item is over 3 days late, for example. This gives buyers and sellers the ultimate level of trust when using your platform.",
        animation: "/animations/homepage/escrow.json",
        links: [{ copy: "Find out more", to: "/what-we-offer#escrow" }],
      }}
      options={{ titleColor: "darkgreen" }}
    />
    <FixedBanner />
  </Layout>
)

export default Marketplace
